import React from "react";

import { Wrapper } from "../../../components/Wrapper";
import { MonitoringContent } from "./MonitoringContent";
import { Advantages } from "../../../components/Advantages/Advantages";
import { FormContactUs } from "../../../components/Forms/FormContactUs";
import { PageHeader1 } from "../../../components/PageHeader1";
import { FormRequest } from "../../../components/Forms/FormRequest";
import img from "./monitoring.jpg";

export const Monitoring = () => {
  window.scroll(0, 0);
  return (
    <div>
      <PageHeader1
        pageName="Мониторинг IT инфраструктуры и сервисов"
        header="Мониторинг IT инфраструктуры — стабильность вашего бизнеса"
        headerText={`В условиях постоянных изменений и роста бизнеса критически важно иметь прозрачность и контроль за состоянием всех элементов IT-ландшафта, включая серверы, сети, приложения и базы данных.
Эффективная система мониторинга позволяет оперативно выявлять потенциальные проблемы, предотвращать их развитие и минимизировать риски, связанные с простоями, сбоями или утратой данных`}
        img={img}
        bgColor="from-[#5b83a8] to-slate-800"
        imgGrColor="from-transparent to-transparent"
        headerColor="text-zinc-50"
        textColor="text-zinc-100"
        bcTextColor="text-zinc-300"
        bcSepColor="text-zinc-400"
      />
      <Wrapper>
        <MonitoringContent />
      </Wrapper>
      <FormRequest />
      <Advantages />
      <FormContactUs />
    </div>
  );
};
