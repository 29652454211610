import React from "react";

export const Wrapper = ({ children, l = true, r = true }) => {
  return (
    <>
      {!l && r && (
        <div className="w-full pr-5 md:pr-10 lg:pr-20">{children}</div>
      )}
      {!r && l && (
        <div className="w-full pl-5 md:pl-10 lg:pl-20">{children}</div>
      )}
      {r && l && (
        <div className="w-full px-5 md:px-10 lg:px-20">{children}</div>
      )}
    </>
  );
};
