import React, { useEffect, useRef } from "react";
import { navItems } from "./navItems";
import { Link } from "react-router-dom";

import { NavMobileItem } from "./NavMobileItem";
import { SocialIcons } from "../SocialIcons";
import { Wrapper } from "../Wrapper";
import { FormRequestModal } from "../Forms/FormRequestModal";

export const NavMobile = ({ setNavMobileOpen }) => {
  const menuRef = useRef();
  const closeMenu = () => {
    menuRef.current.classList.add("translate-x-full");
    setTimeout(() => setNavMobileOpen(false), 150);
  };
  useEffect(() => {
    setTimeout(() => {
      menuRef.current.classList.remove("translate-x-full");
    });
  });
  return (
    <div className="lg:hidden">
      <>
        <div
          onClick={() => closeMenu()}
          className="fixed flex top-0 right-0 left-0 h-lvh backdrop-blur-sm z-20"
        ></div>
        <div
          ref={menuRef}
          className="fixed flex flex-col top-0 right-0 w-full sm:w-1/2 md:w-2/5 h-lvh bg-zinc-600 text-white drop-shadow-2xl z-30 translate-x-full duration-[200ms]"
        >
          {/* Меню навигатора */}
          <div className="pt-[120px] h-full flex flex-col ">
            <div className="flex flex-col justify-between h-full">
              <div className="overflow-auto">
                <Wrapper>
                  {navItems.map((item) =>
                    item.link ? (
                      <Link
                        onClick={() => closeMenu()}
                        key={item.text}
                        to={item.link}
                      >
                        <NavMobileItem item={item} />
                      </Link>
                    ) : (
                      <NavMobileItem
                        closeMenu={closeMenu}
                        key={item.text}
                        item={item}
                      />
                    )
                  )}
                </Wrapper>
              </div>
              <Wrapper>
                <div className="py-4 border-t border-zinc-500 flex md:hidden">
                  {/* <SocialIcons iconsLight={true} /> */}
                  <div>
                    <FormRequestModal
                      filled={true}
                      textHeader="Контакты для связи"
                    >
                      Перезвонить
                    </FormRequestModal>
                  </div>
                </div>
              </Wrapper>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
