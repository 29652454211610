import React from "react";
import { UserSupportContetnt } from "./UserSupportContetnt";
import { Advantages } from "../../../components/Advantages/Advantages";
import { Wrapper } from "../../../components/Wrapper";
import { FormContactUs } from "../../../components/Forms/FormContactUs";
import { PageHeader1 } from "../../../components/PageHeader1";
import { FormRequest } from "../../../components/Forms/FormRequest";
import img from "./teh-podderjka.jpg";

export const UserSupport = () => {
  window.scroll(0, 0);
  return (
    <div>
      <PageHeader1
        pageName="Поддержка пользователей"
        header="Мы придаем особое значение качественной и своевременной поддержке пользователей"
        headerText="Надежная IT-инфраструктура и быстрая реакция на технические вопросы это залог успешной работы вашего бизнеса. Гарантируем оперативную реакцию и высокое качество обслуживания, что позволяет нам не только решать текущие задачи, но и предупреждать возможные проблемы в будущем."
        img={img}
        bgColor="from-[#e9e6f2] to-[#ecebf2]"
        imgGrColor="from-[#e9e6f2] to-transparent"
      />
      {/* <PageHeader pageName="Поддержка пользователей" /> */}
      <Wrapper>
        <UserSupportContetnt />
      </Wrapper>
      <FormRequest />
      {/* <div className="bg-gradient-to-r from-sky-50 to-indigo-100 mb-10"> */}
      <Advantages header="Почему мы?" />
      {/* </div> */}
      <FormContactUs my={true} />
    </div>
  );
};
