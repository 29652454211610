import React, { useEffect, useRef, useState } from "react";

import { Button } from "./Button";
import { FormRequestModal } from "./Forms/FormRequestModal";

export const MainForm = ({ buttonCalcHandler, buttonTariffsHandler }) => {
  const mainForm = useRef();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };
  useEffect(() => {
    setTimeout(() => {
      mainForm.current.classList.remove("opacity-0");
    });

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      ref={mainForm}
      className="opacity-0 transition-opacity duration-[1500ms]"
    >
      <div
        className="w-full pt-[12%] sm:w-3/4 sm:pt-[12%] md:w-3/4 md:pt-[13%] lg:w-1/2 lg:pt-[80px] flex flex-col items-start gap-4 select-none"
        style={{
          transform: `translateY(${offsetY * 0.15}px)`,
        }}
      >
        <h1 className=" text-zinc-50 leading-normal uppercase text-2xl font-bold sm:text-4xl sm:font-normal">
          Профессиональная поддержка
          <br />
          для вашего бизнеса
        </h1>
        <p className="pb-4 text-zinc-100 text-xl font-light">
          Комплексные IT-услуги, которые обеспечивают стабильную и безопасную
          работу ваших сервисов, позволяя вам сосредоточиться на росте и
          развитии.
        </p>
        <div className="flex gap-6 pt-6">
          <div onClick={() => buttonTariffsHandler()}>
            <Button filled={true}>Тарифы</Button>
          </div>
          {/* <div onClick={() => buttonCalcHandler()}> */}
          <div>
            <FormRequestModal
              filledGreen={true}
              textHeader="Контакты для связи"
            >
              Рассчитать стоимость
            </FormRequestModal>
          </div>
        </div>
      </div>
    </div>
  );
};
