import React from "react";
import { PageHeader } from "../../../components/PageHeader";
import { Wrapper } from "../../../components/Wrapper";
import { NewServicesContent } from "./NewServicesContent";
import { Advantages } from "../../../components/Advantages/Advantages";
import { FormContactUs } from "../../../components/Forms/FormContactUs";

export const NewServices = () => {
  window.scroll(0, 0);
  return (
    <div>
      <PageHeader pageName="Построение ИТ инфраструктуры с нуля" />
      <Wrapper>
        <NewServicesContent />
        <Advantages />
        <FormContactUs />
      </Wrapper>
    </div>
  );
};
