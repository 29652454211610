import { Button } from "../Button";
import React, { useState } from "react";
// import { FormQuestion } from "./FormComponents/FormQuestion";
import { Modal } from "../Modal";
import { telegram } from "../../api/telegram";
import { toast, Bounce } from "react-toastify";

export const FormRequestModal = ({
  children,
  textHeader = "Модальное окно",
  header = "ПОМОЖЕМ ВАШЕМУ БИЗНЕСУ УЖЕ СЕГОДНЯ",
  text = `Оставьте заявку на бесплатную консультацию и базовый аудит`,
  buttonText = "Перезвонить",
  filledGreen = false,
  filled = false,
}) => {
  const [isModal, setModal] = useState(false);

  const formControlClasses =
    "w-full px-6 py-2 md:py-2 text-gray-700 focus:ring-1 ring-zinc-400 rounded-full focus:outline-none";
  const controlErrorClasses =
    "absolute -bottom-[30px] left-5 bg-amber-200 lg:left-7 text-sm px-3 py-[2px] rounded-lg text-black";
  const [name, setName] = useState("");
  // const [nameDirty, setNameDirty] = useState(false);
  // const [nameError, setNameError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneDirty, setPhoneDirty] = useState(false);
  const [phoneError, setPhoneError] = useState(true);

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "name":
        // setNameDirty(true);
        break;
      case "phone":
        setPhoneDirty(true);
        break;
      default:
    }
  };
  const nameHandler = (e) => {
    // e.target.value.trim().length > 0 ? setNameError(false) : setNameError(true);
    setName(e.target.value);
  };
  const phoneHandler = (e) => {
    const re = /^[\d\+][\d\(\)\ -]{8,18}\d$/; //eslint-disable-line
    re.test(String(e.target.value).toLocaleLowerCase())
      ? setPhoneError(false)
      : setPhoneError(true);

    setPhone(e.target.value);
  };
  const formClean = () => {
    setName("");
    // setNameDirty(false);
    // setNameError(true);
    setPhone("");
    setPhoneDirty(false);
    setPhoneError(true);
  };

  const buttonClicked = () => {
    // if (!nameError && !phoneError) {
    if (!phoneError) {
      //Отправка формы
      let message = `<strong>Сообщение с сайта DGS:</strong>\nИмя: ${name}\nТелефон: ${phone}`;
      // console.log("Имя: " + name, "Телефон: " + phone, Date());
      telegram(message);
      // Yandex metrika
      window.ym(99128412, "reachGoal", "FormRequest_sent");
      formClean();
      // notify();
      toast.success(
        `Благодарим за Ваше обращение! Перезвоним в ближайщее время!`,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        }
      );
    } else {
      setPhoneDirty(true);
      // setNameDirty(true);
    }
  };

  return (
    <div onClick={() => setModal(true)}>
      <Button filled={filled} filledGreen={filledGreen}>
        {children}
      </Button>
      {isModal && (
        <Modal onClose={() => setModal(false)} textHeader={textHeader}>
          {/* <FormQuestion onClose={() => setModal(false)} /> */}

          <form className={`flex flex-col items-center w-full`}>
            <div className="flex flex-col w-full items-center text-center">
              <div className="flex flex-col items-center gap-10 w-full">
                <div className="w-full relative">
                  <input
                    value={name}
                    name="name"
                    className={formControlClasses}
                    placeholder="Ваше имя"
                    onBlur={(e) => blurHandler(e)}
                    onChange={(e) => nameHandler(e)}
                  ></input>
                  {/* {nameDirty && nameError && (
                <div className={controlErrorClasses}>Просьба заполнить имя</div>
              )} */}
                </div>
                <div className="w-full relative">
                  <input
                    value={phone}
                    name="phone"
                    type="tel"
                    className={formControlClasses}
                    placeholder="Телефон*"
                    onBlur={(e) => blurHandler(e)}
                    onChange={(e) => phoneHandler(e)}
                  ></input>
                  {phoneDirty && phoneError && (
                    <>
                      <div className="absolute -bottom-[10px] left-10 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[13px] border-b-amber-200"></div>
                      <div className={controlErrorClasses}>
                        Укажите корректный номер
                      </div>
                    </>
                  )}
                </div>

                <div
                  className="md:w-full mt-2 md:mt-0"
                  onClick={() => buttonClicked()}
                >
                  <Button filled={true} disabled={false}>
                    {buttonText}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};
