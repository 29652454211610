import React from "react";

export const DataBackupContent = () => {
  return (
    <div className="py-12">
      <h2>Почему резервное копирование и архивация важны?</h2>
      <ul>
        <li>
          <h3>Защита от потери данных:</h3> В случае сбоя оборудования, вирусной
          атаки или случайного удаления данных, резервное копирование помогает
          восстановить информацию без потерь.
        </li>
        <li>
          <h3>Минимизация рисков:</h3> Своевременное создание резервных копий и
          их хранение в безопасном месте защищает вашу компанию от потерь,
          связанных с техническими неполадками, природными катастрофами или
          атаками на системы.
        </li>
        <li>
          <h3>Соблюдение нормативных требований:</h3> Для ряда отраслей
          законодательство требует обязательного резервного копирования и
          архивации данных, чтобы обеспечить долгосрочную сохранность информации
          и соответствие стандартам.
        </li>
        <li>
          <h3>Оптимизация хранения данных:</h3> Архивация позволяет эффективно
          хранить старые или редко используемые данные, освобождая ресурсы для
          более актуальной информации и улучшая производительность системы.
        </li>
      </ul>

      <h2 className="pt-10 pb-5">
        Наши решения для резервного копирования и архивации данных:
      </h2>
      <ol className="ulRingRose">
        <div className="flex flex-col gap-5">
          <li>
            <strong>Регулярное автоматическое резервное копирование:</strong> Мы
            настраиваем систему автоматического резервного копирования данных на
            регулярной основе, что исключает человеческий фактор и обеспечивает
            полную безопасность вашей информации.
          </li>
          <li>
            <strong>Многоуровневая защита:</strong> Мы используем комплексный
            подход к защите данных, включая создание копий на различных
            носителях (локальное хранение, облачные решения, удалённые серверы),
            что повышает надёжность хранения.
          </li>
          <li>
            <strong>Резервное копирование в облако:</strong> Облачные решения
            позволяют создавать резервные копии данных и обеспечивать их
            доступность с любого устройства в любой точке мира, а также защищают
            от потерь, связанных с физическими повреждениями оборудования.
          </li>
          <li>
            <strong>Архивация данных:</strong> Мы обеспечиваем долгосрочное
            хранение данных, которые редко используются, но необходимы для
            архивирования в соответствии с законодательными и внутренними
            требованиями вашей компании.
          </li>
          <li>
            <strong>Гибкость восстановления:</strong> Наши решения позволяют
            восстанавливать данные в любой момент времени, будь то полное
            восстановление системы или восстановление отдельных файлов, что
            минимизирует время простоя и потери.
          </li>
          <li>
            <strong>Контроль и отчётность:</strong> Мы обеспечиваем полный
            контроль над процессом резервного копирования, предоставляя
            регулярные отчёты и уведомления о статусе выполнения копирования и
            архивации данных.
          </li>
        </div>
      </ol>

      <h2 className="pt-10 pb-5">Преимущества наших услуг:</h2>
      <ul>
        <div className="flex flex-col gap-5">
          <li>
            <strong>Надёжность и безопасность:</strong> Использование
            современных технологий для защиты данных и минимизации рисков.
          </li>
          <li>
            <strong>Автоматизация процесса:</strong> Устранение человеческого
            фактора с помощью автоматических решений для регулярного резервного
            копирования.
          </li>
          <li>
            <strong>Гибкость и масштабируемость:</strong> Мы предлагаем решения,
            которые растут вместе с вашим бизнесом и легко адаптируются под
            новые требования.
          </li>
          <li>
            <strong>Доступность и быстрота восстановления:</strong>{" "}
            Восстановление данных происходит быстро и эффективно, обеспечивая
            минимальное время простоя.
          </li>
          <li>
            <strong>Соответствие стандартам:</strong> Обеспечиваем соответствие
            законодательным и корпоративным требованиям по сохранению и защите
            данных.
          </li>
        </div>
      </ul>

      <h3 className="pt-10">
        Резервное копирование и архивация данных с нашей помощью — это
        гарантированная защита вашей информации, минимизация рисков и соблюдение
        всех норм и стандартов безопасности. Мы обеспечиваем бесперебойную
        работу вашей компании даже в самых нестандартных ситуациях.
      </h3>
    </div>
  );
};
