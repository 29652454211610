import React from "react";
import cloud from "./cloudmigration.jpg";
import { FormRequest } from "../../../components/Forms/FormRequest";
export const CloudMigrationContent = () => {
  return (
    <div>
      <section className="grid lg:grid-cols-2 gap-10 py-10 lg:pt-20">
        <img
          className="object-cover min-h-[200px] max-h-[300px] h-full w-full rounded-lg overflow-hidden"
          src={cloud}
          alt="Миграция в облако"
        ></img>

        <div>
          <h1 className="text-3xl font-bold text-black pb-6">
            Поможем перенести вашу инфраструктуру в облако
          </h1>

          <p className="text-black">
            Переход на облачные технологии — это важный шаг для любой компании,
            стремящейся повысить гибкость, снизить затраты на IT-инфраструктуру
            и улучшить доступность сервисов. Мы предлагаем полный спектр услуг
            по переносу инфраструктуры и сервисов в облако, включая анализ,
            проектирование, миграцию и настройку облачных решений, которые
            оптимально подходят для вашего бизнеса.
          </p>
        </div>
      </section>

      <h2 className="pt-5">Этапы переноса инфраструктуры в облако:</h2>
      <ol>
        <li>
          <h3>Анализ текущей инфраструктуры:</h3> Мы начинаем с оценки вашей
          существующей IT-инфраструктуры, чтобы понять, какие компоненты и
          сервисы наиболее подходят для переноса в облако. Мы анализируем ваши
          требования по производительности, безопасности и масштабируемости, а
          также определяем наилучшую модель облачного решения (публичное,
          частное или гибридное облако).
        </li>
        <li>
          <h3>Разработка стратегии миграции:</h3> На основе анализа мы
          разрабатываем индивидуальную стратегию миграции, учитывая минимизацию
          времени простоя, рисков и затрат. Мы определяем последовательность
          шагов и обеспечиваем плавный переход без потери данных и функционала.
        </li>
        <li>
          <h3>Миграция данных и сервисов:</h3> Мы осуществляем перенос всех
          данных, приложений и сервисов в облако, обеспечив их безопасность и
          целостность. Мы контролируем процесс миграции, чтобы все компоненты
          работали без сбоев и переход был максимально безболезненным для
          бизнеса.
        </li>
        <li>
          <h3>Оптимизация облачной инфраструктуры:</h3> После миграции мы
          выполняем настройку и оптимизацию облачной среды, чтобы она работала с
          максимальной производительностью, обеспечивала безопасность и
          соответствовала бизнес-потребностям.
        </li>
        <li>
          <h3>Тестирование и поддержка:</h3> Мы проводим всестороннее
          тестирование всех сервисов и приложений, чтобы убедиться, что они
          функционируют корректно в облаке. Мы также предоставляем услуги по
          мониторингу и технической поддержке, чтобы обеспечить стабильную
          работу облачной инфраструктуры.
        </li>
      </ol>
      <FormRequest />
      <h2 className="pt-10 pb-5">Преимущества переноса в облако:</h2>
      <ul className="ulRingRose">
        <div className="flex flex-col gap-5">
          <li>
            <strong>Снижение затрат на инфраструктуру:</strong> Перенос в облако
            позволяет сократить расходы на приобретение и обслуживание
            физического оборудования, а также уменьшить затраты на управление
            инфраструктурой.
          </li>
          <li>
            <strong>Гибкость и масштабируемость:</strong> Облачные решения
            позволяют легко масштабировать ресурсы в зависимости от роста
            компании и изменяющихся потребностей.
          </li>
          <li>
            <strong>Высокая доступность и отказоустойчивость:</strong> Облачные
            решения обеспечивают высокую доступность и автоматическое
            резервирование данных, минимизируя время простоя и потери.
          </li>
          <li>
            <strong>Управление безопасностью и защита данных:</strong> Мы
            внедряем передовые решения для защиты данных и предотвращения
            внешних угроз, соответствующие международным стандартам
            безопасности.
          </li>
          <li>
            <strong>Производительность и эффективность:</strong> Оптимизация
            облачных сервисов и инфраструктуры позволяет значительно повысить
            производительность и снизить время отклика систем.
          </li>
        </div>
      </ul>

      <h3 className="pt-10">
        Перенос инфраструктуры и сервисов в облако с нашей компанией — это
        решение, которое обеспечит вашему бизнесу технологическую гибкость,
        снизит операционные расходы и обеспечит безопасное, эффективное
        функционирование всех IT-систем. Мы поможем вам сделать этот процесс
        простым и безопасным, независимо от масштаба и сложности вашего бизнеса.
      </h3>
    </div>
  );
};
