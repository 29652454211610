import React from "react";
import { Wrapper } from "../components/Wrapper";
import { FormRequestModal } from "./Forms/FormRequestModal";

export const Tariffs = () => {
  return (
    <div className="bg-zinc-50 py-16">
      <Wrapper>
        <h1 className="text-center pt-6 pb-16">Тарифные планы</h1>
        {/* <div className="flex flex-row gap-6"> */}
        <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-10 md:gap-18 xl:gap-6 pt-7">
          <div className="w-full flex flex-col justify-items-center border border-zinc-400 hover:shadow-2xl rounded-md px-6">
            <div className="px-6 py-3 border-b border-black text-center">
              <h2 className="uppercase text-center text-5xl font-black text-zinc-400">
                A
              </h2>
              <span className="py-3 inline-block align-bottom text-sm text-center text-nowrap">
                от
                <span className="font-extrabold text-3xl"> 10 000</span>
                <span className="align-top"> руб/мес</span>
              </span>
              {/* <p className="text-center">Для микробизнеса</p> */}
            </div>
            <div className="flex flex-col py-6 text-sm">
              <div className="flex flex-row justify-between">
                <p>Компьютеров</p>
                <div>от 5</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Сервер</p>
                <div>0</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Виртуальный сервер</p>
                <div>0</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Маршрутизатор/Wi-Fi точка</p>
                <div>0</div>
              </div>
              <div className="flex flex-row justify-between pt-3">
                <p>Линия техподдержки</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>SLA</p>
                <div>нет</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Первоначальный аудит</p>
                <div>нет</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Мониторинг сервисов 24/7</p>
                <div>нет</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>База знаний</p>
                <div>нет</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Персональный менеджер</p>
                <div>нет</div>
              </div>
            </div>
            <div className="pt-2 pb-6">
              <FormRequestModal
                filledGreen={true}
                textHeader="Контакты для связи"
                buttonText="Обсудить тариф"
              >
                Подробнее
              </FormRequestModal>
            </div>
          </div>
          {/* -------------------------------------------------------------------------- */}
          <div className="w-full flex flex-col justify-items-center border border-zinc-400 hover:shadow-2xl rounded-md px-6">
            <div className="px-6 py-3 border-b border-black text-center">
              <h2 className="uppercase text-center text-5xl font-black text-cyan-500">
                B
              </h2>
              <span className="py-3 inline-block align-bottom text-sm text-center text-nowrap">
                от
                <span className="font-extrabold text-3xl"> 30 000</span>
                <span className="align-top"> руб/мес</span>
              </span>
              {/* <p className="text-center">Для микробизнеса</p> */}
            </div>
            <div className="flex flex-col py-6 text-sm">
              <div className="flex flex-row justify-between">
                <p>Компьютеров</p>
                <div>от 15</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Сервер</p>
                <div>1</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Виртуальный сервер</p>
                <div>1</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Маршрутизатор/Wi-Fi точка</p>
                <div>1</div>
              </div>
              <div className="flex flex-row justify-between pt-3">
                <p>Линия техподдержки</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>SLA</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Первоначальный аудит</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Мониторинг сервисов 24/7</p>
                <div>нет</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>База знаний</p>
                <div>нет</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Персональный менеджер</p>
                <div>нет</div>
              </div>
            </div>
            <div className="pt-2 pb-6">
              <FormRequestModal
                filledGreen={true}
                textHeader="Контакты для связи"
                buttonText="Обсудить тариф"
              >
                Подробнее
              </FormRequestModal>
            </div>
          </div>
          {/* -------------------------------------------------------------------------- */}
          <div className="w-full flex flex-col justify-items-center border border-zinc-400 hover:shadow-2xl rounded-md px-6">
            <div className="px-6 py-3 border-b border-black text-center">
              <h2 className="uppercase text-center text-5xl font-black text-teal-500">
                C
              </h2>
              <span className="py-3 inline-block align-bottom text-sm text-center text-nowrap">
                от
                <span className="font-extrabold text-3xl"> 50 000</span>
                <span className="align-top"> руб/мес</span>
              </span>
              {/* <p className="text-center">Для микробизнеса</p> */}
            </div>
            <div className="flex flex-col py-6 text-sm">
              <div className="flex flex-row justify-between">
                <p>Компьютеров</p>
                <div>от 30</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Сервер</p>
                <div>1</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Виртуальный сервер</p>
                <div>2</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Маршрутизатор/Wi-Fi точка</p>
                <div>1</div>
              </div>
              <div className="flex flex-row justify-between pt-3">
                <p>Линия техподдержки</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>SLA</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Первоначальный аудит</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Мониторинг сервисов 24/7</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>База знаний</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Персональный менеджер</p>
                <div>нет</div>
              </div>
            </div>
            <div className="pt-2 pb-6">
              <FormRequestModal
                filledGreen={true}
                textHeader="Контакты для связи"
                buttonText="Обсудить тариф"
              >
                Подробнее
              </FormRequestModal>
            </div>
          </div>
          {/* -------------------------------------------------------------------------- */}
          <div className="w-full flex flex-col justify-items-center border border-zinc-400 hover:shadow-2xl rounded-md px-6">
            <div className="px-6 py-3 border-b border-black text-center">
              <h2 className="uppercase text-center text-5xl font-black text-rose-400">
                D
              </h2>
              <span className="py-3 inline-block align-bottom text-sm text-center text-nowrap">
                от
                <span className="font-extrabold text-3xl"> 85 000</span>
                <span className="align-top"> руб/мес</span>
              </span>
              {/* <p className="text-center">Для микробизнеса</p> */}
            </div>
            <div className="flex flex-col py-6 text-sm">
              <div className="flex flex-row justify-between">
                <p>Компьютеров</p>
                <div>от 50</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Сервер</p>
                <div>2</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Виртуальный сервер</p>
                <div>3</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Маршрутизатор/Wi-Fi точка</p>
                <div>1</div>
              </div>
              <div className="flex flex-row justify-between pt-3">
                <p>Линия техподдержки</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>SLA</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Первоначальный аудит</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Мониторинг сервисов 24/7</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>База знаний</p>
                <div>да</div>
              </div>
              <div className="flex flex-row justify-between">
                <p>Персональный менеджер</p>
                <div>да</div>
              </div>
            </div>
            <div className="pt-2 pb-6">
              <FormRequestModal
                filledGreen={true}
                textHeader="Контакты для связи"
                buttonText="Обсудить тариф"
              >
                Подробнее
              </FormRequestModal>
            </div>
          </div>
          {/* -------------------------------------------------------------------------- */}
        </div>
      </Wrapper>
    </div>
  );
};
