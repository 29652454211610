import React from "react";

import { Wrapper } from "../../../components/Wrapper";
import { DataBackupContent } from "./DataBackupContent";
import { Advantages } from "../../../components/Advantages/Advantages";
import { FormContactUs } from "../../../components/Forms/FormContactUs";
import img from "./backup.jpg";
import { PageHeader1 } from "../../../components/PageHeader1";
import { FormRequest } from "../../../components/Forms/FormRequest";

export const DataBackup = () => {
  window.scroll(0, 0);
  return (
    <div>
      <PageHeader1
        pageName="Резервное копирование и архивация данных"
        header="Обеспечим надежную защиту ваших данных"
        headerText={`Резервное копирование и архивация данных — это ключевые процессы, обеспечивающие защиту вашей информации от потерь, сбоев и атак.
Мы предлагаем надёжные решения по регулярному резервному копированию и долгосрочной архивации данных, что помогает минимизировать риски и обеспечивать безопасность вашей корпоративной информации`}
        img={img}
        bgColor="from-[#061b36] to-[#0a294d]"
        imgGrColor="from-[#061b36] to-transparent"
        headerColor="text-zinc-50"
        textColor="text-zinc-300"
        bcTextColor="text-zinc-400"
        bcSepColor="text-zinc-600"
      />
      <Wrapper>
        <DataBackupContent />
      </Wrapper>
      <FormRequest />
      <Advantages />
      <FormContactUs />
    </div>
  );
};
