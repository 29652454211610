import React, { useState } from "react";
import { NavItemMenu } from "./NavItemMenu";

//import React, { useState, useRef } from "react";
// import { useClickOutside } from "../hooks/useClickOutside";
// тут не используем хук, для отслеживания клика вне области

export const NavItem = ({ item }) => {
  const [isOpen, setOpen] = useState(false);

  // const itemRef = useRef(null);
  // useClickOutside(itemRef, () => {
  //   children && setOpen(false);
  // });
  //Не используем, нужно для фиксации клика вне области...

  return (
    <div
      onMouseOver={() => item.menuItems && setOpen(true)}
      onMouseOut={() => item.menuItems && setOpen(false)}
      className="relative"
      // ref={itemRef}
      // Выключено, т.к. необходимо для закрытия меню, при клике вне элемена.
    >
      <div className="flex space-x-1 py-4 cursor-pointer items-center">
        <div
          // className={`${
          //   isOpen ? "text-rose-500" : "text-white"
          // } font-medium text-sm uppercase hover:text-rose-500`}
          className={`${
            isOpen && "text-rose-500"
          } font-normal text-sm uppercase hover:text-rose-500`}
          // onClick={() => children && setOpen(!isOpen)}
          // Выключено открытие меню по клику, т.к. открывается при наведении и при клике переход к общей странице.
        >
          {item.text}
        </div>
        {item.menuItems && !isOpen && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="size-5 text-zinc-400"
          >
            <path
              fillRule="evenodd"
              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {item.menuItems && isOpen && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="size-5 text-zinc-400"
          >
            <path
              fillRule="evenodd"
              d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>
      {isOpen && (
        <div
          className={`bg-white flex flex-col ${item.menuWidth} border-[0.5px] border-zinc-400 absolute top-13 right-0 px-4 py-3 font-medium text-sm`}
          // className={`shadow-2xl flex flex-col ${item.menuWidth} bg-white border border-neutral-200  px-4 py-3 rounded-sm divide-y text-neutral-800 font-normal text-sm`}
        >
          <NavItemMenu click={setOpen} item={item} />
        </div>
      )}
    </div>
  );
};
