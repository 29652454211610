import React from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageHeader } from "../../components/PageHeader";
import { FormContactUs } from "../../components/Forms/FormContactUs";
import { TransOpacityOnLoad } from "../../components/TransOpacityOnLoad";

export const PartnerProgram = () => {
  window.scroll(0, 0);
  return (
    <>
      <PageHeader pageName="Партнерская программа" />
      <TransOpacityOnLoad>
        <Wrapper>
          <div className="py-10">
            <h2>
              Присоединяйтесь к нашей партнерской программе и зарабатывайте на
              продвижении наших продуктов и услуг.
            </h2>

            <h3>Условия сотрудничества:</h3>
            <ul className="ulRingRose">
              <li>
                <strong>До 30% от продажи услуг</strong> – щедрая премия за
                успешное привлечение клиентов.
              </li>
              <li>
                <strong>Неограниченное количество договоров:</strong> чем больше
                сделок, тем выше ваш доход.
              </li>
              <li>
                <strong>Расширение ассортимента услуг вашей компании: </strong>
                новые возможности для вашего бизнеса.
              </li>
              <li>
                <strong>Простой процесс подключения:</strong> партнерская
                программа доступна как для юридических, так и для физических
                лиц.
              </li>
            </ul>

            <h3>
              С нами выгодно сотрудничать. Вы можете получать вознаграждение за
              каждый успешный договор.
              <br />
              Начните зарабатывать с нами уже сегодня!
            </h3>

            {/* <p><a href="#">Присоединиться к партнерской программе</a></p> */}
          </div>
          <FormContactUs />
        </Wrapper>
      </TransOpacityOnLoad>
      ;
    </>
  );
};
