import React, { useEffect, useRef } from "react";

export const TransOpacityOnLoad = ({
  children,
  duration = "duration-[200ms]",
}) => {
  const content = useRef();
  useEffect(() => {
    setTimeout(() => {
      content.current.classList.remove("opacity-0");
    }, 100);
  }, []);

  return (
    <div ref={content} className={`opacity-0 transition-opacity ${duration}`}>
      {children}
    </div>
  );
};
