import React from "react";
import { AboutUsContent } from "./AboutUsContent";
import { FormContactUs } from "../../components/Forms/FormContactUs";
import { Wrapper } from "../../components/Wrapper";
import { PageHeader } from "../../components/PageHeader";
import { TransOpacityOnLoad } from "../../components/TransOpacityOnLoad";

// import { Navigate } from "react-router-dom";

export const AboutUs = () => {
  window.scroll(0, 0);
  // Navigate("/about-us");
  return (
    <div>
      <PageHeader pageName="О нас" />
      <Wrapper>
        <TransOpacityOnLoad>
          <AboutUsContent />
        </TransOpacityOnLoad>
        <FormContactUs />
      </Wrapper>
    </div>
  );
};
