import React from "react";

import { Wrapper } from "../../../components/Wrapper";
import { ConsultingAndAuditContent } from "./ConsultingAndAuditContent";
import { Advantages } from "../../../components/Advantages/Advantages";
import { FormContactUs } from "../../../components/Forms/FormContactUs";
import img from "./it-audit.jpg";
import { PageHeader1 } from "../../../components/PageHeader1";
import { FormRequest } from "../../../components/Forms/FormRequest";

export const ConsultingAndAudit = () => {
  window.scroll(0, 0);
  return (
    <div>
      <PageHeader1
        pageName="IT консалтинг и аудит"
        header="IT консалтинг и аудит"
        headerText={`В условиях быстро меняющихся технологий и бизнес-среды важно иметь надежного партнера, который поможет вам не только эффективно управлять текущими IT-ресурсами, но и разработать стратегию развития вашей IT-инфраструктуры. Мы предоставляем услуги IT консалтинга и аудита, направленные на оптимизацию бизнес-процессов, повышение производительности и безопасность ваших систем`}
        img={img}
        bgColor="from-[#5b83a8] to-slate-800"
        imgGrColor="from-transparent to-transparent"
        headerColor="text-zinc-50"
        textColor="text-zinc-100"
        bcTextColor="text-zinc-300"
        bcSepColor="text-zinc-400"
      />
      <Wrapper>
        <ConsultingAndAuditContent />
      </Wrapper>
      <FormRequest />
      <Advantages />
      <FormContactUs />
    </div>
  );
};
