import React from "react";
import { ReactComponent as TeamIcon } from "./team_3.svg";
import { ReactComponent as AimIcon } from "./aim_3.svg";
import { ReactComponent as FinancialAgreementIcon } from "./financial-agreement_3.svg";
import { ReactComponent as MonitoringIcon } from "./monitoring_3.svg";
import { ReactComponent as SecurityGuaranteeIcon } from "./security-guarantee_3.svg";
import { ReactComponent as SupportIcon } from "./support_3.svg";
import { Wrapper } from "../Wrapper";

export const Advantages = ({ header = "Наши преимущества" }) => {
  return (
    <div className="py-20 md:py-30">
      <Wrapper>
        <div className="text-center">
          <h1 className="pb-10">{header}</h1>

          <ul className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 pt-7">
            <li className="flex flex-col items-center px-[15%] md:px-0">
              <div className="relative">
                {/* <TeamIcon className="w-[100px] h-[100px] fill-indigo-300 -skew-x-[70deg] scale-y-[0.3] blur-[4px] absolute top-[22px] left-[30px] -z-10" /> */}
                <TeamIcon className="w-[100px] h-[100px] fill-rose-500" />
                {/* <div className="w-32 h-20 rounded-full absolute blur-[10px] top-[50px] -left-[12px] bg-indigo-200 -z-20 -skew-x-[0deg] scale-x-[1] scale-y-[0.2]"></div> */}
              </div>
              <h3>Квалифицированная команда</h3>
              <p className="text-neutral-700 text-base">
                Наша команда состоит из опытных специалистов, готовых помочь в
                любое время
              </p>
            </li>

            <li className="flex flex-col items-center px-[10%] md:px-0">
              <div className="relative">
                {/* <MonitoringIcon className="w-[100px] h-[100px] fill-indigo-300 -skew-x-[70deg] scale-y-[0.3] blur-[4px] absolute top-[22px] left-[30px] -z-10" /> */}
                <MonitoringIcon className="w-[100px] h-[100px] fill-rose-500" />
              </div>
              <h3>Мониторинг сервисов 24/7</h3>
              <p className="text-neutral-700 text-base">
                Предотвращаем более 90% инцидентов и оперативно устраненяем
                неисправности
              </p>
            </li>

            <li className="flex flex-col items-center px-[10%] md:px-0">
              <FinancialAgreementIcon className="w-[100px] h-[100px] fill-rose-500" />
              <h3>Финансовая ответственность по SLA</h3>
              <p className="text-neutral-700 text-base">
                Мы несем финансовую ответственность за несоблюдение условий
                договора
              </p>
            </li>

            <li className="flex flex-col items-center px-[10%] md:px-0">
              <AimIcon className="w-[100px] h-[100px] fill-rose-500" />
              <h3>Индивидуальный подход</h3>
              <p className="text-neutral-700 text-base">
                Мы учитываем особенности вашего бизнеса и предлагаем решения,
                оптимально подходящие именно вам
              </p>
            </li>

            <li className="flex flex-col items-center px-[10%] md:px-0">
              <SupportIcon className="w-[100px] h-[100px] fill-rose-500" />
              <h3>Онлайн поддержка</h3>
              <p className="text-neutral-700 text-base">
                Обеспечиваем оперативное реагирование, гарантируя минимальные
                простои
              </p>
            </li>

            <li className="flex flex-col items-center px-[10%] md:px-0">
              <SecurityGuaranteeIcon className="w-[100px] h-[100px] fill-rose-500" />
              <h3>Гарантия безопасности</h3>
              <p className="text-neutral-700 text-base">
                Сохранность данных обеспечивается договором о защите
                конфиденциальности (NDA)
              </p>
            </li>
          </ul>
        </div>
      </Wrapper>
    </div>
  );
};
