import { Link, useLocation } from "react-router-dom";

// import { ReactComponent as LogoIcon } from "../images/logo.svg";
// import { MyButton } from "./MyButton";
import { Wrapper } from "./Wrapper";
import { useState } from "react";
import { Phone } from "./Phone";
// import { Button } from "./Button";
import { FormRequestModal } from "./Forms/FormRequestModal";

export const Header = ({ isNavMobileOpen, setNavMobileOpen }) => {
  let location = useLocation();
  //shadowHeader
  const [isShadowHeader, setShadowHeader] = useState(false);
  const changeNavCss = () => {
    window.scrollY > 10 ? setShadowHeader(true) : setShadowHeader(false);
  };
  window.addEventListener("scroll", changeNavCss);
  return (
    <header
      className={
        location.pathname === "/"
          ? `lg:shadow-none bg-white fixed w-full top-0 lg:relative z-50 ${
              isShadowHeader &&
              // "bg-zinc-900/60 lg:bg-transparent backdrop-blur-md"
              "bg-white/80 backdrop-blur-sm shadow-md"
            }`
          : // : `lg:shadow-none bg-white fixed w-full top-0 lg:relative z-50`
            `bg-white/80 backdrop-blur-sm shadow-md fixed w-full top-0 lg:relative z-50`
      }
    >
      <Wrapper>
        <div className="flex justify-between pt-3 lg:pt-3 pb-3 lg:pb-3 items-center">
          <div>
            <Link to="/" className="cursor-pointer">
              {/* <LogoIcon className="w-14 h-14" /> */}
              <div className="w-[50px] h-[50px] flex justify-center items-center rounded-tl-[18px] rounded-br-[18px] text-white bg-black text-base  font-extrabold">
                DGS
              </div>
            </Link>
          </div>

          {/* Правый блок */}
          <div className="flex flex-row space-x-16 items-center">
            {/* <div className="hidden lg:flex flex-col jastify-end text-sm">
              <strong>АДРЕС</strong>
              <div className="flex justify-end text-xs text-gray-700">
                Москва, ул.Центральная, д.12А
              </div>
            </div> */}

            <div className="flex flex-col items-start">
              <strong className="hover:text-rose-500 select-all font-medium text-base text-zinc-950">
                <Phone />
              </strong>

              <a
                href="mailto:welcome@dgs.team"
                className="text-sm text-zinc-400 hover:underline underline-offset-4 select-text"
              >
                welcome@dgs.team
              </a>
            </div>

            <div className="hidden lg:flex flex-col items-start select-none">
              <strong className="font-medium text-base text-zinc-950 pb-1">
                Время работы
              </strong>

              <div className="text-xs font-medium text-zinc-400">
                Пн-Пт 10.00-19.00
              </div>
            </div>
            <div className="hidden md:flex flex-col items-start">
              <FormRequestModal filled={true} textHeader="Контакты для связи">
                Перезвонить
              </FormRequestModal>
            </div>
            <div className="lg:hidden text-zinc-950">
              {!isNavMobileOpen && (
                <svg
                  onClick={() => {
                    setNavMobileOpen(true);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  stroke="currentColor"
                  className="size-12 cursor-pointer z-30"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              )}
              {isNavMobileOpen && (
                <svg
                  onClick={() => {
                    setNavMobileOpen(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  stroke="currentColor"
                  className="size-12 cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </header>
  );
};
