import React from "react";
import { BreadCrumbs } from "./BreadCrumbs";
import { Wrapper } from "./Wrapper";
import defImg from "../images/main_data.jpg";

export const PageHeader1 = ({
  pageName = "Название раздела",
  header = "Заголовок",
  headerText = "Текст заголовка",
  img = defImg,
  bgColor = "from-zinc-200 to-zinc-100",
  imgGrColor = "from-zinc-200 to-transparent",
  headerColor = "",
  textColor = "",
  bcTextColor = "text-zinc-600",
  bcSepColor = "text-zinc-400",
}) => {
  return (
    <div className="grid lg:grid-cols-2 min-h-[450px]">
      <div
        className={`overflow-hidden bg-gradient-to-b lg:bg-gradient-to-l ${bgColor} order-last lg:order-first`}
      >
        <Wrapper>
          <div className="flex flex-col pt-3 lg:pt-6 pb-8 gap-6 select-none">
            <BreadCrumbs textColor={bcTextColor} sepColor={bcSepColor} />
            <h1 className={`pt-6 ${headerColor}`}>{header}</h1>
            <p className={`${textColor}`}>{headerText}</p>
          </div>
        </Wrapper>
      </div>
      <div className="flex min-h-[300px] max-h-[450px] md:h-full lg:min-h-full  relative ">
        <img
          className="object-cover object-center h-full w-full"
          src={img}
          alt={pageName}
        ></img>
        <div className="w-full h-full absolute top-0">
          <div
            className={`w-full lg:w-3/6 h-1/6 lg:h-full absolute bottom-0 lg:top-0 left-0 bg-gradient-to-t lg:bg-gradient-to-r ${imgGrColor}`}
          ></div>
        </div>
      </div>
    </div>
  );
};
