import { useMatches } from "react-router-dom";

export const BreadCrumbs = ({
  textColor = "text-zinc-600",
  sepColor = "text-zinc-400",
}) => {
  let matches = useMatches();
  // console.log(matches);
  let crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => match.handle.crumb(match.data));

  return (
    <ol className={`flex flex-row text-sm ${textColor} text-ellipsis`}>
      {crumbs.map((crumb, index) => (
        <li className="flex flex-row last:pointer-events-none" key={index}>
          {index > 0 && <div className={`px-2 ${sepColor}`}>/</div>}
          <div className={`hover:text-rose-500 text-nowrap text-ellipsis`}>
            {crumb}
          </div>
        </li>
      ))}
    </ol>
  );
};
