import {
  Route,
  Link,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  // withRouter,
  // Navigate,
} from "react-router-dom";

import { Main } from "./pages/Main";
import { Services } from "./pages/Services";
import { Error404 } from "./pages/Error404";
import { HardwareMaintenance } from "./pages/servicePages/HardwareMaintenance/HardwareMaintenance";
import { Layout } from "./components/Layout";
import { LayoutServices } from "./components/LayoutServices";
import { UserSupport } from "./pages/servicePages/UserSupport/UserSupport";
import { KnowledgeBase } from "./pages/servicePages/КnowledgeBase/KnowledgeBase";
import { Monitoring } from "./pages/servicePages/Monitoring/Monitoring";
import { ConsultingAndAudit } from "./pages/servicePages/ConsultingAndAudit/ConsultingAndAudit";
import { Outsourcing } from "./pages/servicePages/Outsourcing/Outsourcing";
import { NewServices } from "./pages/servicePages/NewServices/NewServices";
import { CloudMigration } from "./pages/servicePages/CloudMigration/CloudMigration";
import { ServicesUpdate } from "./pages/servicePages/ServicesUpdate/ServicesUpdate";
import { DataBackup } from "./pages/servicePages/DataBackup/DataBackup";
import { Policy } from "./pages/Policy";
import { LayoutMaintenance } from "./components/LayoutMaintenance";
import { AboutUs } from "./pages/aboutUs/AboutUs";
import { Vacancies } from "./pages/vacancies/Vacancies";
import { PartnerProgram } from "./pages/partnerProgram/PartnerProgram";
import { Bpm } from "./pages/bpm/Bpm";
import { Support1s } from "./pages/support1s/Support1s";
import PageTitle from "./components/PageTitle";

function App() {
  window.scroll(0, 0);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <>
            <PageTitle title="DGS - Профессиональное IT обслуживание, IT аутсорсинг" />
            <Layout />
          </>
        }
        handle={{
          crumb: () => <Link to="/">Главная</Link>,
          name: "DGS - Профессиональное IT обслуживание, IT аутсорсинг",
        }}
      >
        <Route index element={<Main />} />
        <Route
          path="services"
          element={
            <>
              <PageTitle title="Предоставляемые IT услуги" />
              <LayoutServices />
            </>
          }
          handle={{
            crumb: () => <Link to="/services">Услуги</Link>,
            name: "Предоставляемые IT услуги",
          }}
        >
          <Route index element={<Services />} />
          <Route
            path="hardware-maintenance"
            element={
              <>
                <PageTitle title="Обслуживание IT инфраструктуры" />
                <HardwareMaintenance />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/services/hardware-maintenance">
                  Обслуживание IT инфраструктуры
                </Link>
              ),
              name: "Обслуживание IT инфраструктуры",
            }}
          />
          <Route
            path="user-support"
            element={
              <>
                <PageTitle title="Поддержка пользователей" />
                <UserSupport />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/services/user-support">Поддержка пользователей</Link>
              ),
              name: "Поддержка пользователей",
            }}
          />
          <Route
            path="knowledge-base"
            element={
              <>
                <PageTitle title="База знаний" />
                <KnowledgeBase />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/services/knowledge-base">База знаний</Link>
              ),
              name: "База знаний",
            }}
          />
          <Route
            path="monitoring"
            element={
              <>
                <PageTitle title="Монироринг сервисов и инфраструктуры" />
                <Monitoring />
              </>
            }
            handle={{
              crumb: () => <Link to="/services/monitoring">Монироринг</Link>,
              name: "Монироринг сервисов и инфраструктуры",
            }}
          />
          <Route
            path="consulting-and-audit"
            element={
              <>
                <PageTitle title="IT консалтинг и аудит" />
                <ConsultingAndAudit />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/services/consulting-and-audit">
                  IT консалтинг и аудит
                </Link>
              ),
              name: "IT консалтинг и аудит",
            }}
          />
          <Route
            path="outsourcing"
            element={
              <>
                <PageTitle title="IT консалтинг и аудит" />
                <Outsourcing />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/services/outsourcing">IT аутсорсинг</Link>
              ),
              name: "IT аутсорсинг",
            }}
          />
          <Route
            path="new-services"
            element={
              <>
                <PageTitle title="ИТ инфраструктура с нуля" />
                <NewServices />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/services/new-services">
                  ИТ инфраструктура с нуля
                </Link>
              ),
              name: "ИТ инфраструктура с нуля",
            }}
          />
          <Route
            path="cloud-migration"
            element={
              <>
                <PageTitle title="Миграция в облако" />
                <CloudMigration />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/services/cloud-migration">Миграция в облако</Link>
              ),
              name: "Миграция в облако",
            }}
          />
          <Route
            path="services-update"
            element={
              <>
                <PageTitle title="Модернизация инфраструктуры и сервисов" />
                <ServicesUpdate />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/services/services-update">
                  Модернизация инфраструктуры
                </Link>
              ),
              name: "Модернизация инфраструктуры",
            }}
          />
          <Route
            path="data-backup"
            element={
              <>
                <PageTitle title="Резервное копирование" />
                <DataBackup />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/services/data-backup">Резервное копирование</Link>
              ),
              name: "Резервное копирование",
            }}
          />
        </Route>
        {/* IT поддержка */}
        <Route
          path="it-maintenance"
          element={
            <>
              <PageTitle title="IT Обслуживание" />
              <LayoutMaintenance />
            </>
          }
          handle={{
            crumb: () => <Link to="/it-maintenance">IT Обслуживание</Link>,
            name: "IT Обслуживание",
          }}
        >
          <Route index element={<UserSupport />} />
          <Route
            path="user-support"
            // element={<Navigate to="/services/user-support" replace />}
            element={
              <>
                <PageTitle title="Обслуживание: Поддержка пользователей" />
                <UserSupport />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/it-maintenance/user-support">
                  Поддержка пользователей
                </Link>
              ),
              name: "Обслуживание: Поддержка пользователей",
            }}
          />
          <Route
            path="hardware-maintenance"
            element={
              <>
                <PageTitle title="Обслуживание: Обслуживание IT инфраструктуры" />
                <HardwareMaintenance />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/it-maintenance/hardware-maintenance">
                  Обслуживание IT инфраструктуры
                </Link>
              ),
              name: "Обслуживание: Обслуживание IT инфраструктуры",
            }}
          />
          <Route
            path="data-backup"
            element={
              <>
                <PageTitle title="Обслуживание: Резервное копирование" />
                <DataBackup />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/it-maintenance/data-backup">
                  Резервное копирование
                </Link>
              ),
              name: "Обслуживание: Резервное копирование",
            }}
          />
          <Route
            path="monitoring"
            element={
              <>
                <PageTitle title="Обслуживание: Автоматизированный мониторинг сервисов и инфраструктуры" />
                <Monitoring />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/it-maintenance/monitoring">Мониторинг</Link>
              ),
              name: "Обслуживание: Мониторинг инфраструктуры и сервисов",
            }}
          />
          <Route
            path="knowledge-base"
            element={
              <>
                <PageTitle title="Обслуживание: Создание и ведение корпоративной базы знаний" />
                <KnowledgeBase />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/it-maintenance/knowledge-base">База знаний</Link>
              ),
              name: "Обслуживание: Создание и ведение корпоративной базы знаний",
            }}
          />
          <Route
            path="consulting-and-audit"
            element={
              <>
                <PageTitle title="Обслуживание: IT консалтинг и аудит" />
                <ConsultingAndAudit />
              </>
            }
            handle={{
              crumb: () => (
                <Link to="/it-maintenance/consulting-and-audit">
                  IT консалтинг и аудит
                </Link>
              ),
              name: "Обслуживание: IT консалтинг и аудит",
            }}
          />
        </Route>

        <Route
          path="bpm"
          element={
            <>
              <PageTitle title="Автоматизация бизнес процессов" />
              <Bpm />
            </>
          }
          handle={{
            crumb: () => <Link to="/bpm">Автоматизация бизнес процессов</Link>,
            name: "Автоматизация бизнес процессов",
          }}
        />
        <Route
          path="support-1s"
          element={
            <>
              <PageTitle title="Сопровождение 1С" />
              <Support1s />
            </>
          }
          handle={{
            crumb: () => <Link to="/support-1s">Сопровождение 1С</Link>,
            name: "Сопровождение 1С",
          }}
        />
        <Route
          path="about-us"
          element={
            <>
              <PageTitle title="О нас" />
              <AboutUs />
            </>
          }
          handle={{
            crumb: () => <Link to="/about-us">О нас</Link>,
            name: "О нас",
          }}
        />
        <Route
          path="partner-program"
          element={
            <>
              <PageTitle title="Партнерская программа" />
              <PartnerProgram />
            </>
          }
          handle={{
            crumb: () => (
              <Link to="/partner-program">Партнерская программа</Link>
            ),
            name: "Партнерская программа",
          }}
        />
        <Route
          path="vacancies"
          element={
            <>
              <PageTitle title="Вакансии" />
              <Vacancies />
            </>
          }
          handle={{
            crumb: () => <Link to="/vacancies">Вакансии</Link>,
            name: "Вакансии",
          }}
        />
        <Route
          path="policy"
          element={
            <>
              <PageTitle title="Политика обработки, хранения и использования персональных данных" />
              <Policy />
            </>
          }
          handle={{
            crumb: () => <Link to="/policy">Политика</Link>,
            name: "Политика обработки, хранения и использования персональных данных",
          }}
        />

        <Route
          path="*"
          element={
            <>
              <PageTitle title="Страница не найдена" />
              <Error404 />
            </>
          }
          handle={{
            crumb: () => <Link to="404">Страница не найдена</Link>,
          }}
        />
      </Route>
    ),
    {
      future: {
        v7_relativeSplatPath: true,
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_skipActionStatusRevalidation: true,
        v7_skipActionErrorRevalidation: true,
      },
    }
  );

  // Yandex Metrika ----------------------------------------
  router.subscribe((state) => {
    // console.log("new state", state.matches);
    // console.log("path", state.location.pathname);
    // console.log(state.matches.indexOf("/services"));
    // console.log(
    //   state.matches.find(function (item) {
    //     return item.pathname === state.location.pathname;
    //   })
    // );

    let mach = state.matches.find(function (item) {
      return item.pathname === state.location.pathname;
    });
    if ("name" in mach.route.handle) {
      // ? console.log("Заголовок страницы", mach.route.handle.name)
      // : console.log("Имя не найдено");
      window.ym(99128412, "hit", window.location.href, {
        params: {
          title: mach.route.handle.name,
        },
      });
      console.log("Заголовок страницы", mach.route.handle.name);
      console.log("путь", window.location.href);
    } else {
      window.ym(99128412, "hit", window.location.href);
      console.log("Имя не найдено");
      console.log("путь", window.location.href);
    }

    // window.ym(99128412, "hit", window.location.href);
    //   window.ym(99128412, "hit", window.location.href,{params:{
    //     title: 'Контактная информация'
    // }});
  });
  // Yandex Metrika ----------------------------------------

  return (
    //
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: true,
      }}
    />
  );
}
export default App;
