import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { TopPanel } from "./TopPanel";
import { Header } from "./Header";
import { Nav } from "./Nav/Nav";
import { NavMobile } from "./Nav/NavMobile";
import { MailBaner } from "./MailBaner";
import { Footer } from "./Footer";
import { ToastContainer } from "react-toastify";

export const Layout = () => {
  // window.scroll(0, 0);
  const [isNavMobileOpen, setNavMobileOpen] = useState(false);
  return (
    <div className="pt-[74px] lg:pt-0 flex flex-col grow">
      <ToastContainer />
      <TopPanel />

      <Header
        isNavMobileOpen={isNavMobileOpen}
        setNavMobileOpen={setNavMobileOpen}
      />
      <Nav />
      {isNavMobileOpen && <NavMobile setNavMobileOpen={setNavMobileOpen} />}

      <Outlet />

      <Footer />
      <MailBaner />
    </div>
  );
};
