import React from "react";

export const ConsultingAndAuditContent = () => {
  return (
    <div className="py-12">
      <h2>IT консалтинг</h2>
      <p>
        Наши эксперты помогут вам анализировать существующие IT-решения, оценить
        их эффективность и предложить оптимальные пути улучшения. Мы работаем с
        вами для разработки индивидуальных стратегий по внедрению новых
        технологий, модернизации оборудования, автоматизации процессов и
        улучшению взаимодействия между различными подразделениями компании.
      </p>
      <h3>Мы консультируем по следующим направлениям:</h3>
      <ul className="ulRingRose">
        <li>
          Выбор и внедрение IT-решений (облачные сервисы, ERP-системы, CRM и
          другие).
        </li>
        <li>Оптимизация IT-инфраструктуры для повышения производительности.</li>
        <li>Разработка и внедрение стратегии кибербезопасности.</li>
        <li>Автоматизация бизнес-процессов.</li>
        <li>Разработка стратегии цифровой трансформации.</li>
      </ul>

      <h2 className="pt-10">IT аудит</h2>
      <p>
        IT аудит — это процесс независимой оценки текущего состояния вашей
        IT-инфраструктуры, выявление слабых мест, рисков и уязвимостей. Мы
        проводим комплексный аудит, который включает проверку безопасности,
        производительности и соответствия вашей инфраструктуры действующим
        стандартам и нормативам. Наши специалисты помогут вам выявить избыточные
        или неэффективные IT-ресурсы, которые могут требовать оптимизации или
        модернизации.
      </p>
      <h3>Наши услуги по IT аудиту включают:</h3>
      <ul className="ulRingRose">
        <li>Аудит безопасности информационных систем.</li>
        <li>
          Анализ производительности серверов, сетевой инфраструктуры и
          приложений.
        </li>
        <li>
          Оценка рисков, связанных с техническим состоянием и устареванием
          технологий.
        </li>
        <li>Разработка рекомендаций по оптимизации IT-ресурсов.</li>
      </ul>

      <h2 className="pt-10">
        Получив качественный IT консалтинг и аудит, вы сможете повысить
        эффективность своей IT-инфраструктуры, снизить риски и ускорить развитие
        бизнеса.
      </h2>
    </div>
  );
};
