import React from "react";
import { Wrapper } from "./Wrapper";
import { Link } from "react-router-dom";

export const TopPanel = () => {
  return (
    <div className="bg-zinc-600 hidden lg:flex select-none">
      <Wrapper>
        <div className="flex justify-end items-center space-x-8 py-2 text-zinc-400 text-sm">
          <Link className="hover:text-zinc-300 hover:underline" to="/about-us">
            О нас
          </Link>
          <Link
            className="hover:text-zinc-300 hover:underline"
            to="/partner-program"
          >
            Партнерская программа
          </Link>
          <Link className="hover:text-zinc-300 hover:underline" to="/vacancies">
            Вакансии
          </Link>
        </div>
      </Wrapper>
    </div>
  );
};
