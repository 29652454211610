import React, { useEffect, useRef, useState } from "react";
// import main from "../images/main_c.webp";
import main from "../images/main_data.jpg";

export const MainImage = () => {
  const [offsetY, setOffsetY] = useState(0);
  const mainPicRef = useRef();
  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };
  // console.log(offsetY);
  useEffect(() => {
    setTimeout(() => {
      mainPicRef.current.classList.remove("opacity-0");
    }, 100);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="relative">
      <div className="w-full h-[600px] min-h-[850px] sm:min-h-[950px] lg:min-h-[600px] absolute -top-[70px] bg-gradient-to-r from-[#071211cc] from-32% to-transparent to-76% -z-10"></div>
      <img
        ref={mainPicRef}
        // className="object-cover w-full h-[120%] object-top -top-[140px] absolute -z-10 opacity-0 transition-opacity duration-[2000ms] brightness-[0.8]"
        className="object-cover w-full h-[600px] min-h-[850px] sm:min-h-[950px] lg:min-h-[600px] object-center -top-[70px] absolute -z-20 opacity-0 transition-opacity duration-[750ms]"
        style={{
          transform: `translateY(${offsetY * 0.1}px)`,
        }}
        src={main}
        alt="Фоновое изображение"
      ></img>
    </div>
  );
};
