import React from "react";

import { ContextLink } from "../../../components/ContextLink";

export const HardwareMaintenanceContent = () => {
  return (
    <div>
      <p className="pt-10">
        <strong>
          Предлагаем выгодные условия сотрудничества для владельцев крупного,
          среднего и малого бизнеса. Наша компания обеспечивает постоянное
          IT-обслуживание на основе соглашения об уровне сервиса (SLA), что
          гарантирует выполнение всех обязательств в оговоренные сроки и с
          необходимым уровнем качества. Благодаря финансовым гарантиям,
          прописанным в договоре, наши клиенты могут быть уверены, что задачи
          будут решены в срок, удаленная поддержка всегда на связи и готова
          ответить на вопросы в удобное время. Каждая заявка обрабатывается
          мгновенно, и вам не придется ждать нужного специалиста.
        </strong>
      </p>

      <div className="py-10">
        <ul className="ulRingRose">
          <div className="flex flex-col gap-5">
            <li>
              <strong>Мониторинг и диагностика</strong>
              <br /> Мы осуществляем постоянный{" "}
              <ContextLink to="/services/monitoring">
                мониторинг
              </ContextLink>{" "}
              вашей IT инфраструктуры , что позволяет выявлять и устранять
              потенциальные проблемы до их возникновения. Регулярные проверки
              систем гарантируют их бесперебойную работу.
            </li>

            <li>
              <strong>Поддержка оборудования</strong>
              <br />
              Наша команда специалистов осуществляет техническую поддержку
              серверов, сетевых устройств и рабочих станций, обеспечивая их
              исправность и безопасность.
            </li>

            <li>
              <strong>Обновление и оптимизация ПО</strong>
              <br />
              Мы следим за актуальностью используемого программного обеспечения,
              проводим обновления и оптимизацию, что повышает производительность
              и защищенность ваших систем.
            </li>

            <li>
              <strong>Резервное копирование и восстановление данных</strong>
              <br />
              Обеспечиваем надежное{" "}
              <ContextLink to="/services/data-backup">
                резервное копирование
              </ContextLink>{" "}
              данных , что позволяет быстро восстанавливать информацию в случае
              сбоев или потерь.
            </li>

            <li>
              <strong>Консультации и планирование</strong>
              <br />
              Мы предлагаем{" "}
              <ContextLink to="/services/consulting-and-audit">
                консультации
              </ContextLink>{" "}
              по улучшению и модернизации вашей IT инфраструктуры, разрабатывая
              стратегии, соответствующие потребностям вашего бизнеса.
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};
