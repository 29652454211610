import React from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageHeader } from "../../components/PageHeader";
import { TransOpacityOnLoad } from "../../components/TransOpacityOnLoad";

export const Vacancies = () => {
  window.scroll(0, 0);
  return (
    <>
      <PageHeader pageName="Вакансии" />
      <TransOpacityOnLoad>
        <Wrapper>
          <div className="pt-10 pb-40">
            <h3>
              Мы команда профессионалов, которые стремятся развивать
              инновационные IT-решения и предоставлять клиентам
              высококачественные услуги. Если вы хотите работать в динамичной и
              креативной компании, где ценят профессионализм и стремление к
              совершенству, мы будем рады видеть вас в нашей команде.
            </h3>
            <h1 className="pt-10">
              <a
                className="text-rose-500 hover:underline"
                href="mailto:welcome@dgs.team"
              >
                hr@dgs.team
              </a>
            </h1>
          </div>
        </Wrapper>
      </TransOpacityOnLoad>
    </>
  );
};
