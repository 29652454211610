import React from "react";
import { Wrapper } from "../components/Wrapper";
// import { ContextLink } from "../components/ContextLink";
import { MainImage } from "../components/MainImage";
import { MainForm } from "../components/MainForm";
import { PopularServices } from "../components/PopularServices";
import { FormRequest } from "../components/Forms/FormRequest";
import { AboutUsContent } from "./aboutUs/AboutUsContent";
import { FormContactUs } from "../components/Forms/FormContactUs";
import { Advantages } from "../components/Advantages/Advantages";
import { Tariffs } from "../components/Tariffs";

export const Main = () => {
  window.scroll(0, 0);

  const calcRef = React.useRef(null);
  const tariffsRef = React.useRef(null);

  const buttonCalcHandler = () => {
    calcRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  const buttonTariffsHandler = () => {
    tariffsRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  // const element = document.getElementById("calc");

  // const buttonHandler = () => {
  //   element.scrollIntoView();
  // };

  return (
    <>
      <div className="w-full h-[460px] min-h-[460px] overflow-hidden">
        <MainImage />
        <Wrapper>
          <MainForm
            buttonCalcHandler={buttonCalcHandler}
            buttonTariffsHandler={buttonTariffsHandler}
          />
        </Wrapper>
      </div>
      <PopularServices />
      <FormRequest full={true} my={false} />
      <Advantages />
      <div ref={tariffsRef}>
        <Tariffs />
      </div>
      {/* Отключено до добавления калькулятора  */}
      {/* <div ref={calcRef}>
        <Calc />
      </div> */}
      <Wrapper>
        <AboutUsContent />
      </Wrapper>
      <FormContactUs full={true} />
    </>
  );
};
