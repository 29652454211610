import React from "react";

export const KnowledgeBaseContent = () => {
  return (
    <div className="py-12">
      <h2>База знаний позволяет:</h2>
      <ul className="ulRingRose py-10">
        <div className="flex flex-col gap-5">
          <li>Сократить время на решение стандартных запросов и проблем;</li>
          <li>Упростить процесс обучения сотрудников;</li>
          <li>
            Обеспечить доступ к важной информации в любое время и с любого
            устройства;
          </li>
          <li>Повысить общую эффективность работы вашей IT-инфраструктуры.</li>
        </div>
      </ul>

      <p>
        <strong>
          Мы регулярно обновляем и расширяем базу, добавляя новые статьи и
          инструкции, чтобы обеспечить вам всегда актуальную информацию для
          самостоятельного решения возникающих задач.
        </strong>
      </p>
    </div>
  );
};
