import React from "react";

import { ContextLink } from "../../../components/ContextLink";
import { AppearanceUp } from "../../../components/AppearanceUp";

export const UserSupportContetnt = () => {
  return (
    <div className="py-10">
      <AppearanceUp>
        <h1 className="pt-12 pb-10 text-center">Что входит в услугу?</h1>
      </AppearanceUp>
      <AppearanceUp>
        <div className="flex flex-col lg:flex-row rounded-lg">
          <div className="w-full lg:w-24 py-3 lg:py-0 pl-8 lg:pl-0 mb-3 lg:mb-0 flex lg:justify-start bg-gradient-to-r from-zinc-200 to-zinc-50 lg:bg-none">
            <div className="text-5xl font-sans font-bold text-teal-500">1.</div>
          </div>
          <div className="w-full">
            <h2>
              Организация линий поддержки пользователей в соответствии с ITSM и
              контролем выполнения обязательств по уровню сервиса (SLA)
            </h2>

            <p>
              Для эффективного решения запросов пользователей и обеспечения
              стабильности работы систем мы используем лучшие практики
              управления IT-сервисами, соответствующие стандартам ITIL (IT
              Service Management, ITSM). В соответствии с этим подходом, мы
              организуем многозвенную систему поддержки, включая:
            </p>
            <ul className="ulRingRose py-2">
              <li>
                <strong>Первичную линию поддержки</strong> — для быстрого
                решения простых и стандартных запросов.
              </li>
              <li>
                <strong>Вторичную и третью линии</strong> — для решения более
                сложных технических проблем, требующих углубленной диагностики и
                экспертизы.
              </li>
            </ul>
            <p>
              Каждое обращение фиксируется в нашей системе тикетов, что
              позволяет отслеживать статус решения проблемы и соблюдать
              установленные сроки. Мы строго контролируем выполнение
              обязательств по уровням сервиса (SLA), гарантируя вам быстрый
              отклик и соблюдение договорных условий.
            </p>
          </div>
        </div>
      </AppearanceUp>
      <AppearanceUp>
        <div className="mt-10 flex flex-col lg:flex-row rounded-lg">
          <div className="w-full lg:w-24 py-3 lg:py-0 pl-8 lg:pl-0 mb-3 lg:mb-0 flex lg:justify-start bg-gradient-to-r from-zinc-200 to-zinc-50 lg:bg-none">
            <div className="text-5xl font-sans font-bold text-teal-500">2.</div>
          </div>
          <div className="w-full">
            <h2>Создание и ведение корпоративной базы знаний</h2>
            <p>
              Для вашего удобства мы регулярно обновляем и поддерживаем
              корпоративную{" "}
              <ContextLink to="/services/knowledge-base">
                базу знаний
              </ContextLink>
              , которая содержит полезную информацию, пошаговые инструкции и
              рекомендации по решению часто возникающих проблем. Эта база
              доступна для всех наших клиентов и помогает сократить время на
              поиск решения и повысить общую эффективность работы.
            </p>
          </div>
        </div>
      </AppearanceUp>
      <AppearanceUp>
        <div className="mt-10 flex flex-col lg:flex-row  rounded-lg">
          <div className="w-full lg:w-24 py-3 lg:py-0 pl-8 lg:pl-0 mb-3 lg:mb-0 flex lg:justify-start bg-gradient-to-r from-zinc-200 to-zinc-50 lg:bg-none">
            <div className="text-5xl font-sans font-bold text-teal-500">3.</div>
          </div>
          <div className="w-full">
            <h2>
              Администрирование с использованием программного комплекса
              1С-Коннект
            </h2>
            <p>
              Мы предлагаем услугу удаленного администрирования с помощью
              программного комплекса <strong>1С-Коннект</strong>. Это решение
              позволяет нашим специалистам оперативно и безопасно подключаться к
              вашим системам для диагностики, настройки и устранения неполадок
              без необходимости физического присутствия. Мы можем выполнять
              различные задачи — от настройки программного обеспечения и
              обслуживания баз данных до комплексного мониторинга и оптимизации
              работы вашей IT-инфраструктуры.
            </p>
            <p>
              Наши администраторы работают только с использованием надежных и
              проверенных инструментов, обеспечивающих высокий уровень
              безопасности и конфиденциальности данных.
            </p>
          </div>
        </div>
      </AppearanceUp>
    </div>
  );
};
