import React from "react";
import { HardwareMaintenanceContent } from "./HardwareMaintenanceContent";
import { Advantages } from "../../../components/Advantages/Advantages";
import { Wrapper } from "../../../components/Wrapper";
import { FormContactUs } from "../../../components/Forms/FormContactUs";
import img from "./it_infrastruktura.jpg";
import { PageHeader1 } from "../../../components/PageHeader1";
import { FormRequest } from "../../../components/Forms/FormRequest";

export const HardwareMaintenance = () => {
  window.scroll(0, 0);
  return (
    <div>
      {/* <PageHeader pageName="Обслуживание IT инфраструктуры" /> */}
      <PageHeader1
        pageName="Обслуживание IT инфраструктуры"
        header="Надежное и эффективное управление вашей IT инфраструктурой"
        headerText={`В современном бизнесе надежная IT инфраструктура является ключом к успешной деятельности.
Наша компания предлагает комплексное обслуживание IT инфраструктуры, обеспечивая стабильную работу всех компонентов вашей системы`}
        img={img}
        bgColor="from-[#4e7d82] to-gray-800"
        imgGrColor="from-transparent to-transparent"
        // bgColor="from-gray-900 to-teal-900"
        // imgGrColor="from-gray-900 to-transparent"
        headerColor="text-zinc-50"
        textColor="text-zinc-200"
        bcTextColor="text-zinc-300"
        bcSepColor="text-zinc-400"
      />
      <Wrapper>
        <HardwareMaintenanceContent />
      </Wrapper>
      <FormRequest />
      <Advantages />
      <FormContactUs />
    </div>
  );
};
