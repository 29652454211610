import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Wrapper } from "../Wrapper";
import { NavItem } from "./NavItem";
import { navItems } from "./navItems";
// import { SocialIcons } from "../SocialIcons";

export const Nav = () => {
  //StickyNav
  let location = useLocation();
  const [isNavSticky, setNavSticky] = useState(false);
  const changeNavCss = () => {
    window.scrollY > 134 ? setNavSticky(true) : setNavSticky(false);
  };
  // window.addEventListener("scroll", changeNavCss);

  useEffect(() => {
    window.addEventListener("scroll", changeNavCss);
    return () => window.removeEventListener("scroll", changeNavCss);
  }, []);

  return (
    <>
      {/* {isNavSticky && (
        <div className="h-[50px] w-full fixed top-0 z-30 backdrop-blur-sm"></div>
      )} */}
      <nav
        // className={
        //   isNavSticky
        //     ? "sticky top-0 shadow-lg bg-zinc-900/60 backdrop-blur-md z-50 hidden lg:flex select-none"
        //     : `hidden lg:flex select-none z-50 ${
        //         location.pathname === "/"
        //           ? "bg-transparent"
        //           : "bg-zinc-900/60 backdrop-blur-md"
        //       }`
        // }
        className={
          isNavSticky
            ? "sticky top-0 z-50 bg-white/80 backdrop-blur-sm hidden shadow-md lg:flex select-none text-zinc-950"
            : `hidden lg:flex z-50 select-none ${
                location.pathname === "/" ? "bg-white " : "bg-white"
              }`
        }
      >
        <Wrapper>
          <div
            // className={`${
            //   !isNavSticky && location.pathname === "/" && "border-b"
            // } flex flex-row items-center justify-between`}
            className={`${
              !isNavSticky && "border-t-[0.5px] border-zinc-400 text-zinc-950"
            } flex flex-row items-center justify-between`}
          >
            {navItems.map((item) =>
              item.link ? (
                <Link key={item.text} to={item.link}>
                  <NavItem item={item} />
                </Link>
              ) : (
                <NavItem key={item.text} item={item} />
              )
            )}
            {/* <div className="pl-20">
            <SocialIcons />
          </div> */}
          </div>
        </Wrapper>
      </nav>
    </>
  );
};
