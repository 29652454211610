import React from "react";
import { Wrapper } from "../../../components/Wrapper";
import { KnowledgeBaseContent } from "./KnowledgeBaseContent";
import { Advantages } from "../../../components/Advantages/Advantages";
import { FormContactUs } from "../../../components/Forms/FormContactUs";
import { PageHeader1 } from "../../../components/PageHeader1";
import { FormRequest } from "../../../components/Forms/FormRequest";
import img from "./knowledge-base.png";

export const KnowledgeBase = () => {
  window.scroll(0, 0);
  return (
    <div>
      <PageHeader1
        pageName="База знаний"
        header="Создание и ведение корпоративной базы знаний"
        headerText={`Корпоративная база знаний — это важный инструмент для эффективного управления информацией и решения IT-задач в вашей компании.
Мы создаем и поддерживаем актуальную базу знаний, которая содержит подробные инструкции, решения часто возникающих проблем, рекомендации по оптимизации работы с IT-системами и лучшие практики`}
        img={img}
        bgColor="from-[#084478] to-slate-800"
        imgGrColor="from-transparent to-transparent"
        headerColor="text-zinc-50"
        textColor="text-zinc-100"
        bcTextColor="text-zinc-300"
        bcSepColor="text-zinc-400"
      />
      <Wrapper>
        <KnowledgeBaseContent />
      </Wrapper>
      <FormRequest />
      <Advantages />
      <FormContactUs />
    </div>
  );
};
